<template>
	<v-card class="mx-auto mb-5" max-width="1200" rounded="xl">
		<v-toolbar>
			<v-avatar size="45px" class="mr-2">
				<v-img alt="Avatar" :src="post.author.imgURL" />
			</v-avatar>
			<v-toolbar-title>
				<div class="d-flex flex-column">
					<span class="title">{{ post.author.fullname }}</span>
					<span class="subtitle-1">{{ post.course }}</span>
				</div>
			</v-toolbar-title>
			<v-spacer />
			<v-menu bottom left offset-y v-if="post.author.bool">
				<template v-slot:activator="{ on }">
					<v-btn :small="$vuetify.breakpoint.smAndDown" icon v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-list color="transparent" class="pa-0" rounded dense>
						<v-list-item @click="editMode()">
							<v-list-item-title>{{ $t('settings.edit') }}</v-list-item-title>
						</v-list-item>
					</v-list>
					<v-list-item @click="callDeletePost()" dense class="red darken-1">
						<v-list-item-title>{{ $t('settings.delete') }}</v-list-item-title>
					</v-list-item>
				</v-card>
			</v-menu>
		</v-toolbar>

		<template v-if="!editing">
			<v-card-title>{{ post.title }}</v-card-title>
			<v-card-subtitle>
				<time :datetime="post.timestamp">{{ humanTime }}</time>
			</v-card-subtitle>
			<v-card-text class="body-1" style="word-break: break-all; white-space: pre-line">
				{{ post.content }}
			</v-card-text>
		</template>
		<template v-else>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="title"
						type="text"
						:label="$t('newsfeed.title')"
						:placeholder="$t('newsfeed.titleToRemember')"
						:rules="rules.title"
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
					<v-textarea
						v-model="content"
						:label="$t('newsfeed.content')"
						:placeholder="$t('newsfeed.whatAreYouThinking')"
						:rows="1"
						:rules="rules.content"
						:counter="contentMaxLength"
						auto-grow
						outlined
						rounded
						hide-details="auto"
						class="pb-2"
					/>
					<div class="mx-auto d-flex align-center justify-center flex-wrap pt-0 pb-5">
						<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callEditPost()">
							{{ $t('settings.edit') }}
						</v-btn>
					</div>
				</v-form>
			</v-card-text>
		</template>

		<v-card-actions>
			<v-btn text small icon @click="callAprovePost(true)">
				<v-icon>{{ aproved ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
			</v-btn>
			<v-badge class="mr-2" :content="thumbsUp ? thumbsUp : '0'" :value="thumbsUp ? thumbsUp : '0'" inline overlap />

			<v-btn text small icon @click="callAprovePost(false)">
				<v-icon>{{ disaproved ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon>
			</v-btn>
			<v-badge :content="thumbsDown ? thumbsDown : '0'" :value="thumbsDown ? thumbsDown : '0'" inline overlap />

			<v-spacer />

			<v-btn text small class="ml-6" @click="writeComment = !writeComment">
				<v-icon left> mdi-message-outline </v-icon>
				{{ $t('newsfeed.comment') }}
			</v-btn>
			<v-badge :content="comments.length ? comments.length : '0'" :value="comments.length ? comments.length : '0'" inline overlap />
		</v-card-actions>

		<v-card-text>
			<template v-if="writeComment">
				<FeedPostNewComment :post="post" @add="addComment" />
			</template>

			<template v-for="comment in comments">
				<FeedPostComment :key="comment.id" :post="post" :comment="comment" @remove="removeComment" />
			</template>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FeedPost',
	components: {
		FeedPostComment: () => import('@/components/feed/FeedPostComment.vue'),
		FeedPostNewComment: () => import('@/components/feed/FeedPostNewComment.vue'),
	},
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			aproved: this.post.aproved,
			disaproved: this.post.disaproved,
			thumbsUp: this.post.thumbsUp,
			thumbsDown: this.post.thumbsDown,
			comments: this.post.comments,
			editing: false,
			loading: false,
			valid: false,
			title: this.post.title,
			content: this.post.content,
			writeComment: false,
			rules: {
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength }),
				],
				content: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.contentMaxLength) || this.$t('rules.length', { length: this.contentMaxLength }),
				],
			},
		}
	},
	computed: {
		humanTime() {
			return this.$moment(this.post.timestamp).fromNow()
		},
		titleMaxLength() {
			return 64
		},
		contentMaxLength() {
			return 1024 * 10
		},
		...mapGetters({
			setupInfo: 'user/setupInfo',
		}),
	},
	methods: {
		editMode() {
			this.editing = !this.editing
		},
		callEditPost() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editPost({
				post: this.post,
				title: this.title,
				content: this.content,
			})
				.then(({ success, post }) => {
					if (success) {
						this.editMode()
						this.title = post.title
						this.content = post.content
						this.post.title = post.title
						this.post.content = post.content
						this.$refs.form.resetValidation()
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		addComment(comment) {
			this.writeComment = false
			this.comments.unshift(comment)
		},
		removeComment(commentToDelete) {
			this.deleteComment({ post: this.post, comment: commentToDelete }).then((data) => {
				if (data.success) {
					this.comments = this.comments.filter((comment) => comment.id !== commentToDelete.id)
				}
			})
		},
		callAprovePost(toBeAproved) {
			if ((this.aproved && toBeAproved) || (this.disaproved && !toBeAproved)) {
				this.undoAprovePost({ post: this.post }).then((data) => {
					if (data.success) {
						this.aproved = false
						this.disaproved = false
						if (toBeAproved) {
							this.thumbsUp -= 1
						} else {
							this.thumbsDown -= 1
						}
					}
				})
			} else if (this.setupInfo.verified.value) {
				this.aprovePost({ aproved: toBeAproved, post: this.post }).then((data) => {
					if (data.success) {
						if (toBeAproved) {
							if (this.disaproved) {
								this.thumbsDown -= 1
							}
							this.aproved = true
							this.disaproved = false
							this.thumbsUp += 1
						} else {
							if (this.aproved) {
								this.thumbsUp -= 1
							}
							this.aproved = false
							this.disaproved = true
							this.thumbsDown += 1
						}
					}
				})
			} else {
				console.log('needs logged in user')
			}
		},
		callDeletePost() {
			this.deletePost(this.post)
		},
		...mapActions('feed', ['editPost', 'deletePost', 'deleteComment', 'aprovePost', 'undoAprovePost']),
	},
}
</script>

<style scoped>
.v-menu__content {
	border-radius: 24px !important;
}
</style>
